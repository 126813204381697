import { combineReducers } from 'redux';
import common from './common';
function Language(state = { locale: 'zh', locale_desc: '简体中文' }, action) {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return Object.assign({}, state, {
                locale: action.locale,
                locale_desc: action.locale_desc,
            });
        default:
            return state;
    }
}

function ExpireTime(state = { minutes: 24, seconds: 59 }, action) {
    switch (action.type) {
        case 'RESET_SESSION_EXPIRE_TIME':
            return Object.assign({}, state, {
                minutes: action.minutes,
                seconds: action.seconds,
            });
        default:
            return state;
    }
}
function SessionExpireTimer(state = { timer: null }, action) {
    switch (action.type) {
        case 'RESET_EXPIRE_TIMER':
            return Object.assign({}, state, { timer: action.timer });
        case 'CLEAR_EXPIRE_TIMER':
            if (state.timer) {
                clearInterval(state.timer);
            }
            return state;
        default:
            return state;
    }
}

const Reducers = combineReducers({
    Language,
    ExpireTime,
    SessionExpireTimer,
    common,
});
export default Reducers;
