const defaultState = {
    activeKey: '',
    tabs: [],
    menu: [],
    userInfo: {},
    permissions: {},
};

const commonReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_ACTIVEKEY':
            return { ...state, activeKey: action.payload.activeKey };
        case 'SET_TAB':
            return { ...state, tabs: action.payload.tabs, currentTab: action.payload.currentTab };
        case 'SET_MENU':
            return { ...state, menu: action.payload.menu };
        case 'SET_USERINFO':
            return { ...state, userInfo: action.payload.userInfo };
        case 'SET_PERMISSIONS':
            return { ...state, permissions: action.payload.permissions };
        default:
            return state;
    }
};
export default commonReducer;
